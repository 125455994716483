var GetStates = function(url,sessID,countryId,ship_data) {
	var params = {
			HTML: 'ajax/state_request.vsl',
			COUNTRY: countryId,
			ID: sessID
		};

	var selectorPrefix = (ship_data === 1 ? 'ship_state' : 'state');
	var addrSupplPrefix = (ship_data === 1 ? 'ship_address_supplement' : 'address_supplement');

	$.getJSON(
		checkSsl(url),
		params,
		function(data) {
			showResult(data,selectorPrefix,addrSupplPrefix);
		}
	);
}
	
var showResult = function(response,selectorPrefix,addrSupplPrefix) {
	var elNode = $('#' + selectorPrefix + '_tr'); // display HTMLNode containing dropdown
	var elSelect = $('#' + selectorPrefix + '_select'); // DropdownNode

	elSelect.empty(); 

	if(response.result === 'false') {			
		elNode.hide();
		elSelect.val('');
		elSelect.prop('required',false);
	}	else {			
		elNode.show();
		$.each( response.data, function( key, data ) {
			elSelect.append('<option value="' + data.value + '">' + data.label + '</value>');
		});
		elSelect.prop('required',true);
	}

	elSelect.selectedIndex = 0;

	var addrSupplNode = $('#' + addrSupplPrefix + '_tr');
	if(response.showAddressSupplement === '1') {
		addrSupplNode.show();
	} else {
		addrSupplNode.hide();
		$('#' + addrSupplPrefix + '_input').val('');
	}
}

function checkSsl(url) {
	var loc = new String(window.parent.document.location);
	if (loc.indexOf("https://")!= -1)
					secure = true;
	else
					secure = false;

	if (url.indexOf("https://")!= -1 && !secure) {
			url = url.replace("https://", "http://");
			url = url.replace(":443", "");
	} else {
			if (url.indexOf("http://")!= -1 && secure)
			url = url.replace("http://", "https://");
	}

	return url;
}