
/*================================================================*/
/*  Top Link einblenden
/*================================================================*/
$.fn.topLink = function(settings) {
    settings = jQuery.extend({
        min: 1,
        fadeSpeed: 200
    }, settings);
    return this.each(function() {
        //listen for scroll
        var el = $(this);
        el.hide(); //in case the user forgot
        $(window).scroll(function() {
            if($(window).scrollTop() >= settings.min)
                el.fadeIn(settings.fadeSpeed);
            else
                el.fadeOut(settings.fadeSpeed);
        });
    });
};


$(document).ready(function() {

    /*=========================================*/
    /* usage w/ smoothscroll */
    /*=========================================*/
    //set the link
    $('#top-link').topLink({
            min: 400,
            fadeSpeed: 500
        }).click(function(e) {
            $("html, body").animate({ scrollTop: 0 }, 800);
            return false;
        });
    
});


$(window).load(function(){

    /*================================================================*/
    /*  TRIGGER EQUAL COLUMNS AT 767 px 
    /*================================================================*/
    if (document.documentElement.clientWidth > 767) { //if client width is greater than 767px load equal columns

        (function($) {

            $.fn.eqHeights = function() {

                var el = $(this);
                if (el.length > 0 && !el.data('eqHeights')) {
                    $(window).bind('resize.eqHeights', function() {
                        el.eqHeights();
                    });
                    el.data('eqHeights', true);
                }
                return el.each(function() {
                    var curHighest = 0;
                    $(this).children().each(function() {
                        var el = $(this),
                            elHeight = el.height('auto').height();
                        if (elHeight > curHighest) {
                            curHighest = elHeight;
                        }
                    }).height(curHighest);
                });
            };

            $('#equalHeights,#equalHeightsA,#equalHeightsB,#equalHeightsC,#equalHeightsD').eqHeights(); /*one time per page unless you make another id to add here */

        }(jQuery));
    } // end if


      // Carousel
	$('#myCarousel').carousel({
	   interval: 5000
	});
});

/**
 * Scroll to selected frame in framestudio step 2
 *
 * @method     scrollToCurrentFrame
 * @param      {string}  idx     frameindex
 */
var fs_scrollToCurrentFrame = function(idx) {
if($('#kk_fs-list-frames-cont').length && $('#framePic_' + idx).length)
		$('#kk_fs-list-frames-cont').scrollTop( $('#framePic_' + idx).position().top - 34);
}

/**
 * Validate Email regex
 * @param  {string}  emailAddress
 * @return {Boolean}
 */
function isValidEmailAddress(emailAddress) {
    var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(emailAddress);
};

/**
 * Inputs vom Typ 'file' parsen die Daten beim Trigger 'fileselect',
 * sodass man beim 'fileselect' auf die Menge der Files und deren
 * Namen zugreifen kann.
 */
$(document).on('change', ':file', function() {
    var input = $(this),
        numFiles = input.get(0).files ? input.get(0).files.length : 1,
        label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
    input.trigger('fileselect', [numFiles, label]);
});


$(document).ready( function() {
    /**
     * Fügt den oder die Namen der ausgewählten Files in das Input, Div, etc.,
     * welches die Klasse .selected-file-name hat.
     */
    $(':file').on('fileselect', function(event, numFiles, label) {
        $('.selected-file-name').val((numFiles > 1 ? $('.selected-file-name').val() + ', ' : '') +label);
    });

    /**
    * content modul accordion
    * auf und zu klappen des Pfeils im Link Titel
    */
    $('.modul-panel-collapse').on('show.bs.collapse', function () {
        $(this).siblings('.modul-panel-heading').addClass('active');
    });

    $('.modul-panel-collapse').on('hide.bs.collapse', function () {
        $(this).siblings('.modul-panel-heading').removeClass('active');
    });
});