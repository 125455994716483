function setVisible (ID) {
	var boxes = new Array('top100','best_artists','best_promo');
	for(i=0;i< boxes.length;i++) {                
		if(ID == boxes[i]) {
			document.getElementById(ID).style.display = 'block';
			document.getElementById(ID).style.visibility = 'visible';
			document.getElementById('tab_' + ID).className = 'act_tab';
		} else {
			if( document.getElementById('tab_' + boxes[i]) != null){
				document.getElementById(boxes[i]).style.display = 'none';
				document.getElementById(boxes[i]).style.visibility = 'hidden';
				document.getElementById('tab_' + boxes[i]).className = '';
			}
		} 
	}
}  

