var searchFormSend = false;
function displayWindow2(url, width, height)
{ 
  var winname;
  d = new Date;
  t = d.getTime();
  winname = window.open(url ,"Window" + t,'width=' + width + ',height=' + height + ',resizable=0,scrollbars=yes,menubar=no,status=no,resizable=no' );
  winname.moveTo((screen.width-width)/2,(screen.height-height)/2);
}

function displayWindow(url, width, height, scrollbars, resizable, menubar, status)
{ var winname;


  d = new Date;
  t = d.getTime();
  var scrollbars = 1;
  var params = "";
  
  if(scrollbars) {
    params+= ",scrollbars=yes";
  }  else {
    params+= ",scrollbars=no" 
  }
   
  if(resizable) {
    params+= ",resizable=yes";
  }  else {
    params+= ",resizable=no" 
  }

	if(height <= 0){
		height=500;
	    params+= ",resizable=yes" 
	} 
	if(width <= 0) {
		width=650;
	    params+= ",resizable=yes" 
	}  
  
 
  if(menubar) {
    params+= ",menubar=yes";
  }  else {
    params+= ",menubar=no" 
  }

  if(status) {
    params+= ",status=yes";
  }  else {
    params+= ",status=no" 
  }
    

  winname = window.open(url ,"Window" + t,'width=' + width + ',height=' + height + ',' + params );
  winname.moveTo((screen.width-width)/2,(screen.height-height)/2);
}



	/* transfer initial searchparams to URI _______START */
var fActionDone = false;

function setSp() {
	if(fActionDone == false) {
		f = document.getElementById('searchForm');
		fAction = "a/search-results.html";		
		gkp = ($('#searchclassify').length ? parseSp($('#searchclassify').find("option:selected").text()) : '');

		gsp = parseSp($('#searchtext').val());
		
	
		f.action = fAction + '?gsp=' + gsp + '&gkp=' + gkp;
		
		fActionDone = true;
		f.submit();
	}
}

function parseSp(val) {
	return escape(val);
}
/* transfer initial searchparams to URI _______END */

function showMenu(index,stat) {
	document.getElementById('chapter-' + index + '-submenu').style.display = (stat ? 'block' : 'none');
}

function updateFilter(field,send) {
	document.getElementById(field).checked = (document.getElementById(field).checked == true? false : true);
	if(send)
		sendSearchForm();
}


function setFilter(field,filter) {
	if(document.getElementById(field)) {
		document.getElementById(field).checked = true;
		document.getElementById(field + '-text').className += " ftext-selected";
	}
	document.getElementById('filter_nav_all:' + filter).style.display = "block";
}

function resetFilter(filter) {
	document.getElementById('resetfilter:' + filter).value = "1";
	sendSearchForm();
}

function sendSearchForm() {
	if(!searchFormSend) {
		searchFormSend = true;
		document.getElementById('listfilter').submit();
	}
}

function filterColor(id,val) {
	el = document.getElementById('farbe:' + id);
	if(el.value == '')
	{
		el.value = val;
		setFilterColor(id,true,false);
	}
	else
	{
		el.value = '';
		setFilterColor(id,false,false);
	}
	
	sendSearchForm();
}

function setFilterColor(id,stat,update) {
	document.getElementById('farbebox:' + id).style.border = '1px solid ' + (stat ? 'black' : 'white');
	if(update)
		document.getElementById('filter_nav_all:farbe').style.display = "block";
}



function openHexHref(url) {
  String.prototype.hexDecode = function(){var r='';for(var i=0;i<this.length;i+=2){r+=unescape('%'+this.substr(i,2));}return r;}
  document.location.href = url.hexDecode();
}

/*********************************************
* jump to anchor of current location by identifier
* @param string identifier of anchor
* @return bool
*********************************************/
function anchor(ident) {
	if(typeof ident != 'undefindex' && ident != '') {
		loc = self.location.href;
		pos = loc.indexOf('#') ;

		if(pos > -1)
			loc = loc.slice(0,pos);

		href = loc + '#' + ident;

		location.href = href;
	}

	return false;
}